<template>
<div>
  <v-card
    flat
    class="pa-3 mt-2"
     v-for="(marathon, index) in marathonData" :key="index"
  >
    <v-card-text>
      <v-form class="multi-col-validation mt-6" :ref="index">
        <v-row>
          <v-col cols="12">{{index}}</v-col>
        </v-row>
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="index"
              label="Id"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="marathon.name"
              label="Name"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
          <v-text-field
              v-model="marathon.days"
              label="Number of Days"
              dense
              type="number"
              outlined
            ></v-text-field>

          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="marathon.price"
              label="Price"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
      color="primary"
      class="me-3 mt-4 mb-4"
      >
        Save changes
      </v-btn>
    </v-card-actions>
      <v-divider></v-divider>
  </v-card>
</div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup(props) {
    const marathonData = ref({
      weight_loss_01: {
        name: "Марафон похудения",
        price: 499,
        days: 19,
      },
    })


    return {
      marathonData,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
