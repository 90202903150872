<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <admin-settings-marathon :account-data="accountSettingData.account"></admin-settings-marathon>
      </v-tab-item>

      <v-tab-item>
        <admin-settings-security></admin-settings-security>
      </v-tab-item>

      <v-tab-item>
        <admin-settings-info :information-data="accountSettingData.information"></admin-settings-info>
      </v-tab-item>

      <v-tab-item>
        <admin-settings-billing></admin-settings-billing>
      </v-tab-item>
      <v-tab-item>
        <admin-settings-notification :notification-data="accountSettingData.notification"></admin-settings-notification>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiDumbbell,
  mdiMovieEditOutline,
  mdiImageMultipleOutline,
  mdiBookmarkOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import AdminSettingsMarathon from './AdminSettingsMarathon.vue'
import AdminSettingsSecurity from './AdminSettingsSecurity.vue'
import AdminSettingsInfo from './AdminSettingsInfo.vue'
import AdminSettingsBilling from './AdminSettingsBilling.vue'
import AdminSettingsNotification from './AdminSettingsNotification.vue'

export default {
  components: {
    AdminSettingsMarathon,
    AdminSettingsSecurity,
    AdminSettingsInfo,
    AdminSettingsBilling,
    AdminSettingsNotification,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Marathons', icon: mdiDumbbell },
      { title: 'Videos', icon: mdiMovieEditOutline },
      { title: 'Images', icon: mdiImageMultipleOutline },
      { title: 'Other Links', icon: mdiBookmarkOutline },
    ]

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: 'johnDoe',
        name: 'john Doe',
        email: 'johnDoe@example.com',
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: 'The name’s John Deo. I am a tireless seeker of knowledge, occasional purveyor of wisdom and also, coincidentally, a graphic designer. Algolia helps businesses across industries quickly create relevant 😎, scaLabel 😀, and lightning 😍 fast search and discovery experiences.',
        birthday: 'February 22, 1995',
        phone: '954-006-0844',
        website: 'https://themeselection.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
      notification: {
        commentOnArticle: true,
        answerOnForm: true,
        followMe: false,
        newsAnnouncements: false,
        productUpdates: true,
        blogDigest: false,
      },
    }

    return {
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiDumbbell,
        mdiMovieEditOutline,
        mdiImageMultipleOutline,
        mdiBookmarkOutline,
      },
    }
  },
}
</script>
